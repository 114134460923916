var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),(false)?_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'ni ni-tv-2 text-primary',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Icons',
        path: '/icons',
        icon: 'ni ni-planet text-blue'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Maps',
        path: '/maps',
        icon: 'ni ni-pin-3 text-orange'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'User Profile',
        path: '/profile',
        icon: 'ni ni-single-02 text-yellow'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Tables',
        path: '/tables',
        icon: 'ni ni-bullet-list-67 text-red'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Login',
        path: '/login',
        icon: 'ni ni-key-25 text-info'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Register',
        path: '/register',
        icon: 'ni ni-circle-08 text-pink'
      }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Documentation")]),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard"}},[_c('i',{staticClass:"ni ni-spaceship"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Getting started")])],1),_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard"}},[_c('i',{staticClass:"ni ni-palette"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Foundation")])],1),_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard"}},[_c('i',{staticClass:"ni ni-ui-04"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Components")])],1)],1)],1)],2):_vm._e(),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{staticClass:"dashboard-content"},[_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)]),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }